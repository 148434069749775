<template>
  <el-upload
    :class="[{hidden: fileList.length != 0}, {hiddenItem: hiddenItem}]"
    drag
    :action="action"
    :data="uploadParam"
    :file-list="fileList"
    :accept="accept"
    :on-error="handleUploadErr"
    v-loading="loading"
    :before-upload="handleBeforeUpload"
    :on-remove="handleRemoveFile"
    :on-success="handleUploadSuccess">
    <img v-if="url" :src="url" class="image" v-show="fileShowType === 1">
    <div class="upload-info" v-show="fileShowType !== 1 && url">文件已上传</div>
    <div slot="tip" class="el-upload__tip" v-if="tip && url">{{tip}}</div>
    <div class="upload-info" v-if="!url">

     <slot>
       <i class="el-icon-upload"></i>
       <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
       <div slot="tip" class="el-upload__tip" v-if="tip && !url">{{tip}}</div>
     </slot>

    </div>
  </el-upload>

</template>

<script>
  import {auth} from '@/api/space/file';
  import {suffix} from '@/utils/files';
  import {mapGetters} from 'vuex'
  import BMF from 'browser-md5-file';

  const bmf = new BMF();

  export default {
    name: 'upload',
    props: {
      url: {
        type: String,
        default: ''
      },
      tip: {
        type: String,
        default: ''
      },
      accept: {
        type: String,
        default: ''
      },
      uuid: {
        type: String,
        default: ''
      },
      isMd5: {
        type: Boolean,
        default: false,
      },

      hiddenItem: {
        type: Boolean,
        default: true
      },

      fileShowType: {
        type: Number,
        default: 1
      },
    },
    computed: {
      ...mapGetters(['prove'])
    },
    components: {},
    mounted: function () {
    },
    data: function () {
      return {
        fileList: [],
        action: '',
        file: {
          url: '',
          name: '',
          md5: '',
        },
        loading: false,
        fileType: this.fileShowType,
        uploadParam: {},
      }
    },
    methods: {
      handleBeforeUpload(file) {
        let suffixName = suffix(file.name);
        this.fileType = this._fileType(suffixName)
        let {size, name} = file
        let employeeName = this.prove.name
        let dirUuid = this.dirUuid
        let vue = this
        this.loading = true
        if (this.isMd5) {
          bmf.md5(
            file,
            (err, md5) => {
              vue.file.md5 = md5;
            },
            progress => {
              console.log('progress number:', progress);
            },
          );
        }
        name = this.uuid + name
        let type = this.fileType
        return auth({size, type, employeeName, dirUuid, name}).then(res => {
          if (res.code == 0) {
            let fileName = res.data.fileName
            let {host, expire, policy, accessId, signature, dir, callback} = res.data.policy
            let param = {
              'key': dir +fileName,
              policy,
              'OSSAccessKeyId': accessId,
              'success_action_status': '200', //让服务端返回200,不然，默认会返回204
              signature,
              callback,
              expire
            }
            this.file = {name: name, url: host + dir + fileName, md5:  vue.file.md5}
            this.action = host
            this.uploadParam = param
          } else {
            return new Promise.reject()
          }
        }).catch(() => {
          return new Promise.reject()
        })
      },
      handleUploadSuccess(response) {
        this.loading = false
        if (response.Status == 'OK') {
          this.$message.success("上传成功")
          this.$emit('upload-success', this.file)

          this.$emit('update:url', this.file.url)
          this.fileList.push(this.file)
        } else {
          this.$message.error("上传失败")
        }
      },
      handleUploadErr() {
        this.$message.success("上传失败")
      },
      handleRemoveFile(file) {
        for (let i = 0; i < this.fileList.length; i++) {
          if (this.fileList[i].url === file.url) {
            this.fileList.splice(i)
            return
          }
        }
      },
      _fileType(suffix) {
        let imgTypes = ['.jpg', '.jpeg', '.png', '.gif']
        let videoTypes = [".rmvb", ".rm", ".wmv", ".avi", ".3gp", ".mp4"]
        let audioTypes = [".wav", ".midi", ".cda", ".mp3", ".wma"]
        let type = 4
        if (imgTypes.indexOf(suffix.toLowerCase()) >= 0) {
          type = 1
        } else if (videoTypes.indexOf(suffix.toLowerCase()) >= 0) {
          type = 2
        } else if (audioTypes.indexOf(suffix.toLowerCase()) >= 0) {
          type = 3
        }

        return type
      },
    },
  }
</script>

<style lang="scss">


  .el-upload {
    width: 100%;
    height: 100%;

    .el-upload-dragger {
      background-color: transparent;
      min-height: 180px;
      height: 100%;
      width: 100% !important;

      .image {
        width: 100%;
        height: 100%;
      }

      .upload-info {
        .el-icon-upload {
          margin: 0;
          margin-bottom: 10px;
        }

        height: 100%;
        display: flex;
        text-align: center;
        justify-content: center;
        align-content: center;
        flex-direction: column;
      }
    }
  }

  .hiddenItem {
    .el-upload-list__item {
      display: none;
    }
  }

</style>
