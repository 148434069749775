<template>
  <div class="create-basic">
    <el-row :gutter="8" class="item">
      <el-col :span="10">
        <p>
          饮品封面
        </p>
        <upload
          style="height: 368px;margin-top: 8px"
          name="file"
          :fileShowType="1"
          :url.sync="drink.image"
        >
          <div>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <p class="ant-upload-hint">
              分辨率为72*72最佳
            </p>
          </div>

        </upload>
      </el-col>
      <el-col :span="14">
        <div class="item products" v-if="select">
         <div>
           <span class="item-title">设备品牌</span>
           <el-select
             class="item-content item-select"
             size="default"
             v-model="uuid"
             placeholder="选取设备品牌"
             clearable
             @change="handleSelectFactory"
           >
             <el-option v-for="(brand, idx) in brands" :key="idx" :value="brand.uuid" :label="brand.name">
             </el-option>
           </el-select>
         </div>

         <div style="margin-left: 8px">
           <span class="item-title" style="margin-left:16px">产品信息</span>
           <el-select
             class="item-content item-select"
             v-model="productUuid"
             placeholder="选取设备产品"
             filterable
             @change="handleSelectProduct"
           >
             <el-option v-for="(product, idx) in products" :key="idx"
                              :select-value="product.name"
                              :label="product.name" :value="product.uuid">
               <p class="select-item-title">{{ product.name }}</p>
               <p class="select-item-label">{{ product.explain }}</p>
             </el-option>
           </el-select>
         </div>
        </div>

        <div class="item">
          <span class="item-title">饮品名称</span>
          <div class="item-content item-flex">
            <el-input placeholder="请输入饮品名称"
                     class="item-flex-input"
                      style="padding-right: 8px"
                     v-model="drink.name"
                     :disabled="productTmpl.disabled && select"></el-input>
            <el-input placeholder="请输入饮品英文名称"
                     class="item-flex-input"
                     v-model="drink.engName"
                     :disabled="productTmpl.disabled && select"></el-input>
          </div>
        </div>

        <div class="item" >
          <span class="item-title">适用杯量</span>
          <div class="item-content">
            <template v-for="(cup, idx) in cupsOpt">
              <el-checkbox v-model="cup.indeterminate"
                          @click="cup.indeterminate = !cup.indeterminate"
                          :disabled="cup.disabled"
                          :key="idx">{{cup.label}}</el-checkbox>
            </template>
          </div>
        </div>

        <div class="item">
          <span class="item-title">饮品参考价</span>
          <el-row class="item-content item-price" :gutter="16">
            <el-col :span="8" v-for="(opt, idx) in cupsOpt" :key="idx">
              <el-input-number v-model.number="opt.price" :min="0" :step="0.01"
                               size="small"
                              placeholder="单位元" :disabled="opt.disabled || !opt.indeterminate"/>
              <span>{{opt.label}}</span>
            </el-col>
          </el-row>
        </div>

        <div class="item">
          <span class="item-title">饮品说明</span>
          <div>
            <el-input type="textarea" v-model="drink.explain" :disabled="productTmpl.disabled && select"></el-input>
          </div>
        </div>


      </el-col>
    </el-row>
    <div class="create-basic-submit">
      <el-button type="primary" :disabled="!drink.image || !drink.name"
                @click="handleNext">下一步</el-button>
    </div>
  </div>
</template>

<script>
  import Upload from '@/components/Upload'

  export default {
    name: '',
    components: {
      Upload
    },
    props: {
      drink: {
        type: Object,
        default: () => {
          return {}
        }
      },
      select: {
        type: Boolean,
        default: true
      },
      clean: {
        type: Boolean,
        default: true
      },
      cups: {
        type:Array,
        default: () => {
          return []
        }
      }
    },
    watch:{
      cups (v) {
        for (let i = 0; i < v.length; i++) {
          this.cupsOpt[i].price = v[i].price / 100
          this.cupsOpt[i].disabled = false
          this.cupsOpt[i].indeterminate = true
        }
      }
    },
    mounted() {
      if (!this.select) {
        this.$nextTick(() => {

          for (let i = 0; i < this.cups.length; i++) {
            this.cupsOpt[i].price = this.cups[i].price / 100
            this.cupsOpt[i].disabled = false
            this.cupsOpt[i].indeterminate = true
          }
        })
      } else {
        this.$api('equipment.brand.all').then(data => {
          this.brands = data || []
        })
      }
    },
    data: function () {
      return {
        cupsOpt: [
          {label: '小杯', value: 1, price: 0, disabled: true, indeterminate: false},
          {label: '中杯', value: 2, price: 0, disabled: true, indeterminate: false},
          {label: '大杯', value: 4, price: 0, disabled: true, indeterminate: false},
        ],
        brands: [],
        products: [],
        uuid:'',
        productUuid:'',
        productTmpl: {
          cups: 0,
          disabled: true,
        },
      }
    },
    methods: {
      handleSelectFactory(value) {
        if (value) {
          this.$api('equipment.brand.products', {uuid: value}).then(data => {
            this.products = data || []
          })
        }
      },
      handleSelectProduct(value){
        if (value) {
          this.productTmpl.disabled = false
          let model = this.products.find((item) => {return item.uuid === value})
          if (model) {
            for (let i = 0; i < this.cupsOpt.length; i++) {
              this.cupsOpt[i].disabled = (model.drinkSpecBit & this.cupsOpt[i].value) === 0
              this.cupsOpt[i].indeterminate = (model.drinkSpecBit & this.cupsOpt[i].value) !== 0
            }
            this.drink.explain = model.explain
            this.drink.productUuid = model.uuid
            this.drink.modelUuid = model.modelUuid
          }

        } else {
          this.productTmpl.disabled = true
          for (let i = 0; i < this.cupsOpt.length; i++) {
            this.cupsOpt[i].disabled = true
            this.cupsOpt[i].indeterminate = true
          }
        }
      },
      handleNext() {
        let drinkSpec = this.drink.drinkSpec || []
        if (this.clean) {
          this.drink.drinkSpec = []
        }
        for (let i = 0; i < this.cupsOpt.length; i++) {
          let opt = this.cupsOpt[i]
           if (!opt.disabled && opt.indeterminate) {
             if (this.clean) {
               this.drink.drinkSpec.push({
                 name: opt.label,
                 price: opt.price * 100,
                 textFlag: i + ``,
                 materials:((drinkSpec[i] && drinkSpec[i].materials)||[]),
               })
             } else {
               this.drink.drinkSpec[i].price =  opt.price * 100
               this.drink.drinkSpec[i].textFlag =   i + ``
             }
         }
        }
        this.$emit('next')
      }
    },
  }
</script>

<style lang="scss" scoped>
  .select-item-title {
    @include font-medium();
  }



  .create-basic {

    .products {
      display: flex;
      justify-items: center;
      align-items: baseline;

      .item-select {
        flex: 1;
      }
    }

    .item {
      padding: 0 $middle-space;
      margin-bottom: $small-space;


      &-title {
        @include font-little();
        margin-right: $small-space;
      }

      &-content {
        margin-top: 4px;
        margin-bottom: $middle-space;
        min-width: 160px;
      }


      &-price {

        span {
          text-align: center;
          display: block;
          margin-top: 4px;
          @include font-little();
        }
      }

      &-products {
        min-height: 120px;
        margin: 4px;
        padding: 0 $small-space $small-space;
        border-radius: $box-mini-radius;


        .tip {
          @include font-little();
        }

        &-tag {
        }
      }

      &-products-border {
        border: 1px dashed #e0e0e0;
      }

      &-flex {
        display: flex;
      }
    }

    &-submit {
      margin-top: $large-space;
      text-align: center;
    }

  }
</style>
